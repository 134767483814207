<div class="layout-topbar mainHeader innerHeader">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/BusinessApps_logo.svg" alt="logo" />
        <!-- <span>ob-kick-start</span> -->
    </a>
    <p class="preview" *ngIf="previewIcon">preview</p>
    <!-- <div class="layout-menu-dropdown">
        <ng-container *ngIf="authService.loggedInUser.myOrganizations">
            <label class="labelWidth">
                <p-dropdown [options]="authService.myAppDefinition" (onChange)="onAppDefinitionChanged($event)"
                    [(ngModel)]="selectedAppDefinition" optionLabel="name" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Please select Organization Unit">
                </p-dropdown>
            </label>
        </ng-container>
    </div> -->

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{
            'layout-topbar-menu-mobile-active':
                layoutService.state.profileSidebarVisible
        }">
        <button (click)="showRightPanel = !showRightPanel" class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
    </div>
</div>

<p-sidebar position="right" [(visible)]="showRightPanel" [baseZIndex]="10000">
    <h3 style="font-weight: normal"></h3>

    <div style="text-align: center">
        <div class="avatarImg">
            <img src="../../assets/layout/images/avatar.png" alt="avatar" />
        </div>
        <!-- <div class="row">
            <button pButton pRipple type="button" label="Primary" class="p-button-rounded"></button>
        </div> -->
        <div class="row">
            <label for="organization-dropdown"></label>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p-dropdown (onChange)="OnChangeOrganization($event)"
                    [options]="this.authService.loggedInUser.myOrganizations"
                    [(ngModel)]="this.authService.loggedInUser.activeOrganizationId" optionLabel="name"
                    optionValue="id"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <label for="email"></label>
                {{ this.authService.loggedInUser.email }}
            </div>
        </div>

        <!-- <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <label for="billing"> Billing Status :</label>
                {{ bill?.subscriptionStatus }}
            </div>
        </div>

        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <label for="nextBillingDate">Next Billing Date :</label>
                {{ bill?.nextBillDate | date : "medium" }}
            </div>
        </div> -->

        <div class="row">
            <div class="logoutbtn col-md-12">
                <button (click)="authService.logout()">Logout</button>
            </div>
        </div>
    </div>
</p-sidebar>