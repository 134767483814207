import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
    requestCounter = 0; // for loader
    constructor(
        private authService: AuthService,
        // public loader: LoaderService,
        private toastre: ToastrService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<any> {
        // if (this.authService.getAuthToken()) {
            if(localStorage.getItem('id_token')) {
                request = this.setHeaders(request);
            }
        // }
        
        return next.handle(request).pipe(
            tap({
                // next: () => null,
                error: (error: HttpErrorResponse) => {
                    if (
                        (error && error.error && error.error.status == 401) ||
                        (error && error.status == 401)
                    ) {

                        return this.authService.refreshToken().pipe(
                            switchMap((data: any) => {
                              let authResult = data;
                              localStorage.setItem('id_token', authResult.idToken);
                              localStorage.setItem('id_token_claims_obj', JSON.stringify(authResult.idTokenClaims));
                          
                              return next.handle(this.setHeaders(request));
                            }),
                            catchError((error: HttpErrorResponse) => {
                              this.authService.login();
                              return throwError(error);
                            }
                          ));

                        // this.authService.login();
                        // return throwError(() => error);
                    } else if (error.error && error.error.status == 409) {
                        return throwError(() => error);
                    } else if (error && error.error && error.error.status == 422) {
                        this.toastre.error(`${error.error.serviceErrors[0]}`);
                    } else if (error && error.error && error.error.status == 402) {
                        this.toastre.error(
                            'Operation was declined by the server. Payment Required'
                        );
                    } else if (error && error.error && error.error.status == 404) {
                        this.toastre.error(`${error.error.serviceErrors[0]}`);
                    } else if (error && error.error && error.error.status != 401) {
                        return this.handleError(error);
                    }
                },
            })
        );
    }

    handleError(error: any) {
        if (error instanceof HttpErrorResponse) {
            if (
                (error &&
                    error.error &&
                    error.error.serviceErrors[0] !=
                        'Token is no longer valid. Please log `back in.' &&
                    error.error.status == 400 &&
                    error.error.status == 404 && 
                    error.error.status == 403 ) ||
                error.error.serviceErrors
            ) {
                this.toastre.error(`${error.error.serviceErrors[0]}`);
            }
        } else {
            return throwError(() => error);
        }
    }

    showLoader(req: HttpRequest<any>) {
        if (
            !(req.headers.has('noloader') || req.url.includes('/connect/token'))
        ) {
            // this.loader.show();
        }
    }
    hideLoader() {
        // this.loader.hide();
    }

    setHeaders(request: HttpRequest<any>) {
    
        const appDefinitionId = localStorage.getItem('AppID');
        if (appDefinitionId == null) {
            if (request.url.includes('FormCoPilot_CreateForm')) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                        OrganizationId: localStorage.getItem('organizationId') ? localStorage.getItem('organizationId') : '',
                    },
                });
            } else {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                        OrganizationId: localStorage.getItem('organizationId') ? localStorage.getItem('organizationId') : '',
                    },
                });
            }
        } else {
            if (request.url.includes('FormCoPilot_CreateForm')) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                        OrganizationId: localStorage.getItem('organizationId') ? localStorage.getItem('organizationId') : '',
                    },
                });
            } else {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                        OrganizationId: localStorage.getItem('organizationId') ? localStorage.getItem('organizationId') : '',
                    },
                });
            }
        }
    }
}
