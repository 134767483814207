<!-- <ng-container *ngIf="spinner">
    <div class="overlay"></div>
    <p-progressSpinner
        [style]="{ width: '100px', height: '100px' }"
        styleClass="custom-spinner"
        strokeWidth="2"
        animationDuration=".5s"
    ></p-progressSpinner>
</ng-container> -->
<!-- <router-outlet *ngIf="this.authService.loadApplication"></router-outlet> -->

<div  
    (mousemove)="onUserAction()"
    (click)="onUserAction()"
    (keypress)="onUserAction()"
>

<router-outlet *ngIf="!isIframe"></router-outlet>

</div>
<p-dialog [(visible)]="idleTimeDialog" header="Idle Time" [modal]="true" [style]="{ width: '450px' }">
    <div class="flex flex-start">
        <div>
            <p>
                <strong>Note:</strong> You have been idle for
                <strong>20 minutes</strong> and will be logged out in
                <strong>{{ countdownSeconds }} seconds</strong>
            </p>
            <p>Press ok to continue your session.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">

        <!-- <button class="cancelbtn" (click)="onStopWork()">
            No
        </button> -->
        <button class="addbtn" (click)="onContinueWork()">
            Ok
        </button>
    </ng-template>
</p-dialog>