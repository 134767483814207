import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from './auth-config';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppEndPoint } from './EndPointUrls/appEndPoints.enum';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['../assets/layout/styles/layout/businessapps.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    spinner: boolean = true;
    subscribtions?: Subscription;
    idleTimeDialog = false;
    idleState = 'NOT_STARTED';
    title: string = 'Business Apps';
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    queryparams: any = null;
    URL: string = '';
    countdown?: number | null = null;
    lastPing?: Date | null = null;
    setTime = 1200;
    isDisplayed = false;
    currentTime = new Date();
    @ViewChild('continueWorkSession') continueWorkSession!: TemplateRef<any>;
    constructor(
        public authService: AuthService,
        public loader: LoaderService,
        public cd: ChangeDetectorRef,
        private idle: Idle,
        private router: Router,
        private keepalive: Keepalive,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        // this.setIdleTimeout();
    }


    ngOnInit(): void {

        if (!sessionStorage.getItem("requested_url")) {
            sessionStorage.setItem("requested_url", window.location.href.toString());
        }

        this.isIframe = window !== window.parent && !window.opener;
        this.URL = window.location.href;
        const accounts = this.msalService.instance.getAllAccounts();

        console.log('accounts', accounts);
        
        const queryReturnIsAuth = this.GetParam('isAuthenticated');
        const queryReturnReturn = this.GetParam('returnurl');

        if (queryReturnIsAuth == 'true') {
            if (this.URL.includes('login'))
                window.location.href = window.location.href.replace('/login', '')
        }
        if (accounts.length == 0 && queryReturnIsAuth == 'true' && !this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
            this.authService.login();
        } else if (accounts.length == 0 && queryReturnIsAuth != 'true') {
            if (!this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
                window.location.href = `${environment.Azure_AD_B2C.GalaxyLoginUrl}?returnurl=${window.location.href}`;
            } else {
                this.navigate_to_requested_url()
            }
        }

        if (accounts.length > 0) {
            // this.router.navigate(['dashboard']) 
            // this.authService.getUserInfo_and_navigate()
            this.navigate_to_requested_url()
        }

        /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {
                if (this.msalService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {

                let payload = result.payload as AuthenticationResult;
                localStorage.setItem('id_token', payload.idToken);

                localStorage.setItem('id_token_claims_obj', JSON.stringify(payload.idTokenClaims));


                let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                    this.msalService.instance.setActiveAccount(payload.account);
                }

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.msalService.instance.getAllAccounts()
                        .find((account: AccountInfo) =>
                            account.idTokenClaims?.oid === idtoken.oid
                            && account.idTokenClaims?.sub === idtoken.sub
                            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount
                    };

                    // silently login again with the signUpSignIn policy
                    this.msalService.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow (see above ln. 74-92).
                 */
                if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
                    let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
                        scopes: []
                    };

                    this.authService.login(signUpSignInFlowRequest);
                }

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                // Checking for the forgot password error. Learn more about B2C error codes at
                // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
                if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                    let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.resetPassword.authority,
                        scopes: [],
                    };

                    this.authService.login(resetPasswordFlowRequest);
                };
            });



    }

    private navigate_to_requested_url() {
          let redirectUrl = sessionStorage.getItem('requested_url');
          let url = redirectUrl.replace(environment.AUTH_REDIRECT_URI, '');
          sessionStorage.removeItem('requested_url');
          if (url == '/' || url == '') {
            // this.router.navigateByUrl(url);
            this.router.navigate([AppEndPoint.dashboard]);
          } else {
            this.router.navigateByUrl(url);
          }
    }

    setLoginDisplay() {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    }

    GetParam(name: any) {
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return 0;
        }
        return results[1] || 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }
    
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    sortParams(link: any) {
        if (this.URL.includes('?')) {
            let queryParams = this.URL.split('?')[1];
            let params = queryParams.split('&');
            let pair = null;
            let data: any = null;
            params.forEach((d) => {
                pair = d.split('=');
                data[`${pair[0]}`] = pair[1];
            });
            return data;
        }
        else {
            return ''
        }
    }

    reset() {
        // we'll call this method when we want to start/reset the idle process
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = 'NOT_IDLE';
        this.countdown = null;
        this.lastPing = null;
    }

    onContinueWork() {
        this.idleTimeDialog = false;
        this.idle.clearInterrupts();
        this.idle.setIdle(5);
        this.idle.setTimeout(this.setTime);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.reset();
    }

    setIdleTimeout(): void {
        this.idle.setIdle(5);
        this.idle.setTimeout(this.setTime);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = 'IDLE';
        });
        this.idle.onIdleEnd.subscribe(() => {
            // this.reset();
            this.idleState = 'NOT_IDLE';
            this.countdown = null;
            this.cd.detectChanges();
        });

        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'TIMED_OUT';
            sessionStorage.clear();
            localStorage.clear();
            // this.msalService.setup_AND_Configure_OIDC();
            // this.msalService.logout();
        });

        this.idle.onTimeoutWarning.subscribe((seconds) => {
            if (seconds <= 300 && !this.idleTimeDialog) {
                this.idle.clearInterrupts();
                this.idleTimeDialog = true;
            }
            this.countdown = seconds;
        });
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    }
}
